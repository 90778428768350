import * as React from "react";
import styled from "@emotion/styled";
import { border, padding } from "polished";

const Wrapper = styled.div({
  flexGrow: 1,
});

const Required = styled.span(({ theme }) => ({
  color: theme.colors.red,
}));

const Label = styled.label(({ theme }) => ({
  ...theme.typography.BodyM,
  display: "block",
  marginBottom: theme.spacing[4],
}));

type InputProps = {
  hasError?: boolean;
  format?: "default" | "cursive";
};

const Input = styled.input<InputProps>(({ theme, hasError, format }) => [
  {
    ...theme.typography.Body,
    ...border(1, "solid", hasError ? theme.colors.red : theme.colors.gray100),
    ...padding(theme.spacing[2], theme.spacing[4]),
    borderRadius: theme.borders.radius.xsmall,
    width: "100%",
    "::placeholder": {
      display: "flex",
      alignItem: "center",
    },
  },
  format === "cursive" && {
    fontFamily: "Brush Script MT, Brush Script Std, cursive",
    fontSize: 40,
  },
]);

const TextArea = styled.textarea<InputProps>(({ theme, hasError }) => ({
  ...theme.typography.Body,
  ...border(1, "solid", hasError ? theme.colors.red : theme.colors.gray100),
  ...padding(0, theme.spacing[4]),
  borderRadius: theme.borders.radius.xsmall,
  width: "100%",
  fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif`,
  resize: "vertical",
}));

const Error = styled.div(({ theme }) => ({
  ...theme.typography.Caption,
  color: theme.colors.red,
  paddingTop: theme.spacing[4],
}));

type InputFieldProps = {
  name: string;
  value: string;
  onChange: (payload: { name: string; value: string }) => void;
  type?: "input" | "textarea";
  required?: boolean;
  error?: string;
  rows?: number;
  format?: "default" | "cursive";
};

export const InputField: React.FC<InputFieldProps> = ({
  name,
  value,
  onChange,
  required,
  error,
  type = "input",
  rows = 3,
  format = "default",
}) => {
  const onBlur = React.useCallback(
    (e) => {
      onChange({ name, value: value.trim() });
    },
    [onChange, name, value]
  );
  const handleOnChange = React.useCallback(
    (e) => {
      onChange({ name, value: e.target.value });
    },
    [onChange, name]
  );
  const inputProps = {
    name,
    value,
    onChange: handleOnChange,
    hasError: !!error,
    onBlur,
    "aria-required": required,
    "aria-label": name,
    format,
  };
  return (
    <Wrapper>
      <Label>
        <label htmlFor={name}>
          {name}
          {required ? (
            <>
              {" "}
              <Required>*</Required>
            </>
          ) : null}
        </label>
      </Label>
      {
        {
          input: <Input {...inputProps} />,
          textarea: <TextArea {...inputProps} rows={rows} />,
        }[type]
      }
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};
