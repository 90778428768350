import * as React from "react";
import styled from "@emotion/styled";
import { urls } from "../../../helpers/urls";
import { useHistory } from "react-router-dom";
import { siteMap } from "../content/siteMap";
import { padding } from "polished";
import { Link } from "../../../components/Link";
import { urlToPageTitle } from "../../../helpers/urlToPageTitle";

const List = styled.ul(({ theme }) => ({
  ...padding(
    theme.spacing[12],
    theme.spacing[60],
    theme.spacing[8],
    theme.spacing[60]
  ),
  display: "flex",
  margin: 0,
  alignItems: "center",
  flexWrap: "wrap",
  backgroundColor: theme.colors.gray,
  [theme.breakpoints.phone]: {
    ...padding(theme.spacing[12], theme.spacing[32]),
  },
}));

const ListItem = styled.li<{ isActive?: boolean }>(({ theme, isActive }) => [
  {
    ...theme.typography.Caption,
    display: "inline",
    whiteSpace: "nowrap",
    padding: theme.spacing[8],
    ":first-of-type": {
      paddingLeft: 0,
    },
  },
  isActive && {
    textDecoration: "underline",
  },
]);

const Divider = styled.div(({ theme }) => ({
  color: theme.colors.gray200,
}));

export const Breadcrumbs: React.FC = () => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const item = siteMap.find(
    ({ href }) =>
      (href !== urls.home && pathname && pathname.indexOf(href) === 0) ||
      (href === urls.home && pathname === urls.home)
  );
  const children = item?.children ?? [];

  return children.length === 0 ? null : (
    <List>
      {children.map(({ href }, index) =>
        href ? (
          <React.Fragment key={href}>
            {index === 0 ? null : <Divider>&#8226;</Divider>}
            <ListItem isActive={pathname === href}>
              <Link href={href}>{urlToPageTitle[href]}</Link>
            </ListItem>
          </React.Fragment>
        ) : null
      )}
    </List>
  );
};
