import * as React from "react";
import styled from "@emotion/styled";
import { staff } from "./content/staff";
import { border, size } from "polished";
import { Title } from "../../components/Typography";

const Wrapper = styled.div(({ theme }) => ({
  paddingTop: theme.spacing[20],
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, 100%)",
  gridGap: theme.spacing[20],
}));

type ImageProps = { src: string };

const Image = styled.div<ImageProps>(({ theme, src }) => ({
  ...border(2, "solid", theme.colors.gray100),
  ...size(176),
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url("${src}")`,
  backgroundSize: "cover",
  [theme.breakpoints.tabletPortrait]: {
    ...size(100),
  },
}));

const Person = styled.div(({ theme }) => ({
  padding: theme.spacing[20],
  display: "flex",
  alignItems: "center",
  ...border(2, "solid", theme.colors.gray100),
  background: theme.colors.gray,
  borderRadius: theme.borders.radius.default,
  marginTop: theme.spacing[12],
  [theme.breakpoints.tabletPortrait]: {
    flexDirection: "column",
    paddingBottom: 0,
  },
}));

const Name = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeights.bold,
  textAlign: "center",
  marginTop: theme.spacing[16],
}));

const Role = styled.div({
  textAlign: "center",
});

const Bio = styled.div(({ theme }) => ({
  ...border("left", 1, "solid", theme.colors.gray100),
  paddingLeft: theme.spacing[28],
  marginLeft: theme.spacing[28],
  alignSelf: "stretch",
  [theme.breakpoints.tabletPortrait]: {
    borderLeft: "none",
    ...border("top", 1, "solid", theme.colors.gray100),
    paddingLeft: 0,
    marginLeft: 0,
    paddingTop: theme.spacing[20],
    marginTop: theme.spacing[20],
  },
}));

const Strengths = styled.p(({ theme }) => ({
  fontWeight: theme.fontWeights.bold,
}));

const Photo = styled.div({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  flexShrink: 0,
  width: "33%",
});

export const Staff: React.FC = () => {
  return (
    <>
      <Title>Meet our staff</Title>
      <p>
        Our dedicated staff is professionally trained to give our seniors and
        volunteers the guidance and support they need. With our many years of
        experience working with seniors and volunteers, we have learned a lot
        about the needs of seniors, both spoken and those that are
        unacknowledged. Our staff is recognized as a valued resource
        knowledgeable about the needs of the homebound and frail elderly in the
        community.
      </p>
      <p>
        We provide individual attention to our seniors and volunteers to help
        ensure the success of our matches.
      </p>
      <Wrapper>
        {staff.map(({ name, role, bio, photo, strengths }) => (
          <Person key={name}>
            <Photo>
              <Image src={photo} />
              <Name>{name}</Name>
              <Role>{role}</Role>
            </Photo>
            <Bio>
              {bio}
              <Strengths>{strengths}</Strengths>
            </Bio>
          </Person>
        ))}
      </Wrapper>
    </>
  );
};
