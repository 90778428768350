import * as React from "react";
import styled from "@emotion/styled";
import { Facebook, LinkedIn } from "../../../components/Icons";
import { phone } from "../../../helpers/constants";
import { StayConnected } from "./StayConnected";
import { urls } from "../../../helpers/urls";
import { Link } from "../../../components/Link";
import { border } from "polished";

const Wrapper = styled.div(({ theme }) => ({
  ...border("top", 1, "solid", theme.colors.black),
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginTop: theme.spacing[28],
  paddingTop: theme.spacing[20],
  [theme.breakpoints.phone]: {
    border: 0,
    marginTop: 0,
    paddingTop: 0,
    display: "flex",
    flexDirection: "column-reverse",
    gap: 0,
  },
}));

const SocialIcons = styled.div(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexBasis: "50%",
  justifyContent: "flex-end",
}));

const SocialIcon = styled.div(({ theme }) => ({
  marginLeft: theme.spacing[16],
}));

const Address = styled.div(({ theme }) => ({
  ...theme.typography.CaptionM,
  textAlign: "center",
  flexGrow: 1,
  flexBasis: "50%",
  [theme.breakpoints.phone]: {
    textAlign: "left",
  },
}));

const CenterRight = styled.div(({ theme }) => ({
  display: "flex",
  flexBasis: "66%",
  [theme.breakpoints.phone]: {
    paddingBottom: theme.spacing[24],
  },
}));

export const Footer: React.FC = () => (
  <Wrapper>
    <StayConnected />
    <CenterRight>
      <Address>
        3 Washington Square Village, Suite&nbsp;1F
        <br />
        New York, NY 10012
        <br />
        {phone}
      </Address>
      <SocialIcons>
        <SocialIcon>
          <Link
            href={urls.linkedIn}
            ariaLabel="Visiting Neighbors LinkedIn page"
          >
            <LinkedIn size="large" />
          </Link>
        </SocialIcon>
        <SocialIcon>
          <Link
            href={urls.facebook}
            ariaLabel="Visiting Neighbors Facebook page"
          >
            <Facebook size="large" />
          </Link>
        </SocialIcon>
      </SocialIcons>
    </CenterRight>
  </Wrapper>
);
