import * as React from "react";
import styled from "@emotion/styled";
import { NavigationType } from "../types";
import { Link } from "../../../components/Link";
import { useHistory } from "react-router-dom";
import { useToggle } from "../../../hooks/useToggle";
import { border, padding, margin } from "polished";
import { urls } from "../../../helpers/urls";
import { NavigationItemLink } from "./NavigationItemLink";
import { urlToPageTitle } from "../../../helpers/urlToPageTitle";

const Wrapper = styled.div<{ isActive: boolean }>(({ theme, isActive }) => ({
  ...(isActive ? border("bottom", 3, "solid", theme.colors.yellow) : {}),
  marginLeft: theme.spacing[40],
  whiteSpace: "nowrap",
  position: "relative",
  [theme.breakpoints.smallDesktop]: {
    marginLeft: theme.spacing[32],
  },
  [theme.breakpoints.tabletLandscape]: {
    marginLeft: theme.spacing[28],
  },
}));

const Menu = styled.div(({ theme }) => ({
  ...padding(theme.spacing[8], theme.spacing[8]),
  position: "absolute",
  top: `calc(100% - ${theme.spacing[4]})`,
  left: 0,
  boxShadow: theme.shadows.menuDropShadow,
  backgroundColor: theme.colors.white,
  minWidth: 120,
  borderRadius: theme.borders.radius.small,
  zIndex: theme.zIndex.popover,
}));

const LinkWrapper = styled.div(({ theme }) => ({
  ...padding(theme.spacing[8], theme.spacing[12]),
  fontWeight: theme.fontWeights.bold,
  "&:hover": {
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borders.radius.small,
  },
}));

const Divider = styled.div(({ theme }) => ({
  ...margin(
    theme.spacing[16],
    theme.spacing[12],
    theme.spacing[16],
    theme.spacing[12]
  ),
  ...border("top", 1, "solid", theme.colors.gray100),
  width: 36,
}));

export const NavigationItem: React.FC<NavigationType> = ({
  href,
  children,
}) => {
  const history = useHistory();
  const {
    on: isHovering,
    turnOff: turnOffHovering,
    turnOn: turnOnHovering,
  } = useToggle();
  const pathname = history.location.pathname;
  return (
    <Wrapper
      isActive={
        (href !== urls.home && pathname && pathname.indexOf(href) === 0) ||
        (href === urls.home && pathname === urls.home)
      }
      onMouseEnter={turnOnHovering}
      onMouseLeave={turnOffHovering}
      onTouchStart={turnOnHovering}
      onTouchEnd={turnOffHovering}
    >
      <NavigationItemLink href={href}>
        {urlToPageTitle[href]}
      </NavigationItemLink>
      {children && isHovering && (
        <Menu>
          {children.map(({ href: subHref }, index) =>
            !!subHref ? (
              <Link href={subHref} key={subHref} onClick={turnOffHovering}>
                <LinkWrapper>{urlToPageTitle[subHref]}</LinkWrapper>
              </Link>
            ) : (
              <Divider key={`divider-${index}`} />
            )
          )}
        </Menu>
      )}
    </Wrapper>
  );
};
