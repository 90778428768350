import * as React from "react";
import styled from "@emotion/styled";
import { isValidEmail } from "../../../helpers/isValidEmail";
import { Button } from "../../../components/Button";
import { InputField } from "../../../components/InputField";
import { padding } from "polished";
import { useForm } from "../../../hooks/useForm";
import { useContactTemplate } from "../../../hooks/useContactTemplate";

const Form = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  flexGrow: 1,
  maxWidth: 340,
  [theme.breakpoints.phone]: {
    maxWidth: "100%",
  },
}));

const FieldButton = styled.div(({ theme }) => ({
  ...padding(theme.spacing[4], 0, theme.spacing[4], theme.spacing[8]),
}));

const Title = styled.div(({ theme }) => ({
  ...theme.typography.BodyM,
  paddingBottom: theme.spacing[4],
}));

const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
});

type FormType = { email: string };
const defaultForm = { email: "" };

export const StayConnected: React.FC = () => {
  const [form, setForm] = React.useState<FormType>(defaultForm);
  const error = !isValidEmail(form.email) ? "Enter a valid email" : "";
  const onFieldChange = React.useCallback(
    ({ value }: { value: string }) => {
      setForm({ email: value });
    },
    [setForm]
  );
  const hasFormErrors = !!error;

  const contactTemplate = useContactTemplate({
    email: form.email,
    message: "I would like to stay connected",
    subject: "[VN Website] Stay connected request",
  });

  const {
    isSubmitting,
    status,
    onSubmit,
    hasClickedSubmit,
    recaptchaComponent,
  } = useForm({
    ...contactTemplate,
    setForm,
    defaultForm,
    hasFormErrors,
    recaptchaSiteKey: "REACT_APP_RECAPTCHA_SITE_KEY_CONTACT_INVISIBLE",
  });

  return (
    <Wrapper>
      <Title>Stay connected!</Title>
      <Form>
        <InputField
          onChange={onFieldChange}
          name="Email"
          value={form.email}
          error={hasClickedSubmit ? error : ""}
        />
        <FieldButton>
          <Button
            onClick={onSubmit}
            size="small"
            disabled={hasClickedSubmit && hasFormErrors}
            loading={isSubmitting}
          >
            Submit
          </Button>
        </FieldButton>
      </Form>
      {status}
      {recaptchaComponent}
    </Wrapper>
  );
};
