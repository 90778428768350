import * as React from "react";
import styled from "@emotion/styled";
import { border, padding, margin } from "polished";
import { siteMap } from "../content/siteMap";
import { Link } from "../../../components/Link";
import { useTheme } from "@emotion/react";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { Footer } from "./Footer";
import { urlToPageTitle } from "../../../helpers/urlToPageTitle";

const Wrapper = styled.div(({ theme }) => ({
  ...border("top", 2, "solid", theme.colors.black),
  ...padding(theme.spacing[28], theme.spacing[48]),
  backgroundColor: theme.colors.gray,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  position: "relative",
  [theme.breakpoints.tabletLandscape]: {
    ...padding(theme.spacing[28], theme.spacing[32]),
  },
  [theme.breakpoints.phone]: {
    ...padding(
      theme.spacing[28],
      theme.spacing[32],
      theme.spacing[32],
      theme.spacing[32]
    ),
  },
}));

const SiteMap = styled.div(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
}));

const Divider = styled.div(({ theme }) => ({
  ...margin(theme.spacing[16], 0),
  ...border("top", 1, "solid", theme.colors.gray300),
  width: 36,
}));

const LinkWrapper = styled.div(({ theme }) => ({
  marginBottom: theme.spacing[8],
  whiteSpace: "nowrap",
}));

const Sublink = styled.span(({ theme }) => ({
  ...theme.typography.CaptionM,
  color: theme.colors.gray300,
}));

const Section = styled.div(({ theme }) => ({
  ...padding(0, theme.spacing[48], theme.spacing[20], 0),
  minWidth: 175,
}));

export const BottomNavigation: React.FC = () => {
  const theme = useTheme();
  const isPhone = useBreakpoint(theme.breakpoints.phone);
  return (
    <Wrapper role="navigation" aria-label="Footer">
      {!isPhone && (
        <SiteMap>
          {siteMap.map(
            ({ href, children, locations }) =>
              (locations ?? []).indexOf("bottom") > -1 && (
                <Section key={href}>
                  <LinkWrapper>
                    <Link href={href}>{urlToPageTitle[href]}</Link>
                  </LinkWrapper>
                  {children?.map(({ href: subHref }) =>
                    !!subHref ? (
                      <LinkWrapper key={subHref}>
                        <Link href={subHref}>
                          <Sublink>{urlToPageTitle[subHref]}</Sublink>
                        </Link>
                      </LinkWrapper>
                    ) : (
                      <Divider key="divider" />
                    )
                  )}
                </Section>
              )
          )}
        </SiteMap>
      )}
      <Footer />
    </Wrapper>
  );
};
