import * as React from "react";
import { Status } from "../components/Status";
import { Alert, Email } from "../components/Icons";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

type RecaptchaSiteKeys =
  | "REACT_APP_RECAPTCHA_SITE_KEY_CONTACT_INVISIBLE"
  | "REACT_APP_RECAPTCHA_SITE_KEY_CONNECT_INVISIBLE"
  | "REACT_APP_RECAPTCHA_SITE_KEY_NOTES_INVISIBLE";

export const useForm = ({
  templateParams,
  templateId,
  setForm,
  defaultForm,
  hasFormErrors,
  recaptchaSiteKey,
}: {
  templateParams: {};
  templateId: string;
  setForm: (params: any) => void;
  defaultForm: {};
  hasFormErrors: boolean;
  recaptchaSiteKey: RecaptchaSiteKeys;
}) => {
  const {
    REACT_APP_EMAILJS_USERID: userId,
    REACT_APP_EMAILJS_SERVICEID: serviceId,
    [recaptchaSiteKey]: siteKey,
  } = process.env as any;

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [hasClickedSubmit, setHasClickedSubmit] = React.useState(false);
  const [recaptchaRef, setRecaptchaRef] = React.useState<ReCAPTCHA | null>();

  const onSubmit = React.useCallback(async () => {
    setHasClickedSubmit(true);
    if (!hasFormErrors) {
      setHasSubmitted(false);
      setHasError(false);
      setIsSubmitting(true);
      try {
        const recaptcha = await recaptchaRef?.executeAsync();
        if (!recaptcha) {
          throw new Error("No recaptcha");
        }
        await emailjs.send(
          serviceId,
          templateId,
          { ...templateParams, "g-recaptcha-response": recaptcha },
          userId
        );
        setHasSubmitted(true);
        setForm(defaultForm);
        setIsSubmitting(false);
        setHasClickedSubmit(false);
      } catch (error) {
        console.log(error);
        setHasError(true);
        setIsSubmitting(false);
      }
    }
  }, [
    hasFormErrors,
    recaptchaRef,
    defaultForm,
    templateParams,
    setHasClickedSubmit,
    setForm,
    serviceId,
    templateId,
    userId,
  ]);

  const status = (
    <>
      {hasError && (
        <Status type="error" icon={Alert}>
          Error submitting. Try again or give us a call.
        </Status>
      )}
      {hasSubmitted && (
        <Status type="success" icon={Email}>
          Form submitted! We look forward to receiving your message.
        </Status>
      )}
    </>
  );

  const recaptchaComponent = (
    <div aria-hidden="true" tabIndex={-1}>
      <ReCAPTCHA sitekey={siteKey} ref={setRecaptchaRef} size="invisible" />
    </div>
  );

  return {
    isSubmitting,
    hasClickedSubmit,
    setHasClickedSubmit,
    onSubmit,
    status,
    recaptchaComponent,
  };
};
