import * as React from "react";
import { Divider } from "../components/Divider";
import { Title } from "../components/Typography";
import { ActionBox } from "../components/ActionBox";
import { Image } from "../components/Image";
import { Button } from "../components/Button";
import talentShow2022 from "../assets/photos/talentShow2022-2.jpg";
import Friends5 from "../assets/photos/friends5.jpg";
import Friends from "../assets/photos/friends.jpg";
import Talent from "../assets/photos/talentShow30-2.jpg";
import { urls } from "../helpers/urls";
import { padding, transparentize } from "polished";
import styled from "@emotion/styled";

/** Hero stock image found here
 * https://www.pexels.com/photo/joyful-adult-daughter-greeting-happy-surprised-senior-mother-in-garden-3768131
 * Photo by Andrea Piacquadio from Pexels
 * Licensing as of 10/24/2021 says:
 *  ✓ Free to use.
 *  ✓ No attribution required.
 */
import HeroImage from "../assets/hero.jpg";
import { useBreakpoint } from "../hooks/useBreakpoint";
import { useTheme } from "@emotion/react";
import { WeServe } from "../components/WeServe";
import { margin } from "polished";
import { Link } from "../components/Link";

const Caption = styled.span(({ theme }) => ({
  fontStyle: "italic",
  ...theme.typography.Body,
}));

const Photos = styled.div(({ theme }) => ({
  ...padding(theme.spacing[32], 0),
  display: "flex",
  gap: theme.spacing[24],
  justifyContent: "center",
  [theme.breakpoints.phone]: {
    alignItems: "center",
    flexDirection: "column",
  },
}));

const Photo = styled.div(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: "50%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: theme.spacing[16],
  maxWidth: 400,
}));

const TikTokContainer = styled.div(({ theme }) => ({
  left: 0,
  width: "100%",
  position: "relative",
  flexBasis: "50%",
  borderRadius: theme.borders.radius.default,
}));

const TikTokVideo = styled.iframe({
  width: "100%",
  minHeight: 580,
  border: 0,
});

const Highlight = styled.span(({ theme }) => ({
  color: theme.colors.aqua,
  fontWeight: "bold",
}));

const Accent = styled.span(({ theme }) => ({
  ...theme.typography.Subtitle,
  ...theme.typography.accent,
  color: theme.colors.aqua,
}));

const Actions = styled.div(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.tabletPortrait]: {
    flexDirection: "column",
    padding: 0,
  },
}));

const Stats = styled.div(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  ...padding(theme.spacing[24], 0, theme.spacing[16], 0),
  gap: theme.spacing[40],
  [theme.breakpoints.tabletPortrait]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const heroSizeDesktop = 500;
const heroSizeTablet = 400;
const heroSizeMobile = 300;

const navPaddingDesktop = 112;
const navPaddingTablet = 96;
const navPaddingMobile = 72;

const Wrapper = styled.div(({ theme }) => ({
  paddingTop: heroSizeDesktop - navPaddingDesktop,
  [theme.breakpoints.tabletLandscape]: {
    paddingTop: heroSizeTablet - navPaddingTablet,
  },
  [theme.breakpoints.phone]: {
    paddingTop: heroSizeMobile - navPaddingMobile,
  },
}));

const Hero = styled.div(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  backgroundImage: `url("${HeroImage}")`,
  backgroundSize: "cover",
  backgroundPosition: "center 30%",
  height: heroSizeDesktop,
  width: "100vw",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  [theme.breakpoints.tabletLandscape]: {
    height: heroSizeTablet,
  },
  [theme.breakpoints.phone]: {
    height: heroSizeMobile,
  },
}));

const TitleWrapper = styled.div(({ theme }) => ({
  color: theme.colors.aqua,
}));

const Header = styled.div(({ theme }) => ({
  ...theme.typography.HeaderB,
  ...theme.typography.accent,
}));

const Announcement = styled.div(({ theme }) => ({
  ...margin(theme.spacing[24], theme.spacing[40], 0, 0),
  boxShadow: theme.shadows.cardShadow,
  padding: theme.spacing[20],
  borderRadius: theme.borders.radius.default,
  background: transparentize(0.2, theme.colors.gray),
  width: 500,
  [theme.breakpoints.phone]: {
    display: "none",
  },
}));

const AnnouncementTitle = styled.div(({ theme }) => ({
  ...theme.typography.BodyB,
  flexGrow: 1,
  width: "100%",
}));

const ButtonWrapper = styled.div({
  display: "flex",
  justifyContent: "flex-end",
});

const Stat = styled.div(({ theme }) => ({
  ...padding(0, theme.spacing[20]),
  ...theme.typography.Header,
  color: theme.colors.aqua,
  textAlign: "center",
  maxWidth: 300,
  [theme.breakpoints.phone]: {
    ":last-child": {
      paddingBottom: 0,
    },
  },
}));

const StatNumber = styled.div(({ theme }) => ({
  ...theme.typography.Subtitle,
  color: theme.colors.black,
  lineHeight: "40px",
  paddingBottom: theme.spacing[12],
}));

export const Home: React.FC = () => {
  const defaultTheme = useTheme();
  const isPhone = useBreakpoint(defaultTheme.breakpoints.phone);
  return (
    <>
      <Hero aria-label="Background image woman hugging senior">
        <Announcement>
          <AnnouncementTitle>
            Celebrating 50+ years of providing crucial services to seniors and
            people who care about them.
          </AnnouncementTitle>
          <p>
            <Highlight>
              Visiting Neighbors wishes everyone a happy and healthy summer.
              We're an organization for all seasons and volunteers are welcome
              to join us any time!
            </Highlight>
          </p>
          <ButtonWrapper>
            <Button variation="secondary" href={urls.volunteer.getInvolved}>
              Learn more
            </Button>
          </ButtonWrapper>
        </Announcement>
      </Hero>
      <Wrapper>
        <TitleWrapper>
          <Title>Welcome to Visiting Neighbors!</Title>
        </TitleWrapper>
        <Header>
          <i>&ldquo;Neighbors helping neighbors&rdquo; since 1972</i>
        </Header>
        <p>
          With 50+ years of experience, Visiting Neighbors has been helping
          seniors in New York City live meaningful and active lives by matching
          them with one of our dedicated volunteers. Our programs and services
          help seniors maintain their independence by connecting them with
          someone who cares. Our goal is to provide a helping hand to our
          community's elderly, who may not have family or friends nearby.
        </p>
        <Stats>
          <Stat color={defaultTheme.colors.aqua}>
            <StatNumber>
              44,000+
              {isPhone ? " " : <br />}
              seniors
            </StatNumber>
            Assisted by Visiting Neighbors since 1972
          </Stat>
          <Stat color={defaultTheme.colors.red}>
            <StatNumber>
              11,000+
              {isPhone ? " " : <br />}
              neighbors
            </StatNumber>
            Served through Shop & Escort and Friendly Visiting
          </Stat>
          <Stat color={defaultTheme.colors.red}>
            <StatNumber>
              13,000+
              {isPhone ? " " : <br />}
              hours
            </StatNumber>
            Of telephone reassurance in 2021
          </Stat>
        </Stats>
        <WeServe />
        <Divider />
        <Actions>
          <ActionBox
            imgAlt="Steve with senior"
            imgSrc={Friends5}
            cta="Explore"
            url={urls.programs.main}
            title="Our programs"
          >
            Visiting Neighbors provides a lifeline, keeping seniors independent
            and connected to the community.
          </ActionBox>
          <ActionBox
            imgAlt="Performer at the talent show"
            imgSrc={talentShow2022}
            cta="Donate"
            url={urls.donate}
            title="Giving"
          >
            Every contribution goes towards helping seniors and supporting
            neighborhood programs.
          </ActionBox>
          <ActionBox
            imgAlt="Two friends together"
            imgSrc={Friends}
            cta="Volunteer"
            url={urls.volunteer.getInvolved}
            title="Get involved"
          >
            From shopping to playing cards, we have several opportunities to
            connect with seniors.
          </ActionBox>
        </Actions>
      </Wrapper>
      <Divider />
      <Accent>
        Our 30th Annual Senior Talent Show was a hit, and our seniors proved
        that "talent is ageless!"
      </Accent>
      <Photos>
        <Photo>
          <TikTokContainer>
            <TikTokVideo
              src="https://www.tiktok.com/embed/v2/7362778110019783979"
              allowFullScreen
              scrolling="no"
              allow="encrypted-media"
            />
          </TikTokContainer>
          <Caption>
            <i>"5th year judging the Visiting Neighbors senior talent show!"</i>{" "}
            <p>
              <Link href="https://www.tiktok.com/@ebottcher?refer=embed">
                @ebottcher (Erik Bottcher)
              </Link>
              <br />
              District 3 - New York City Council
            </p>
            <p>
              <Link href="https://www.tiktok.com/music/I'm-So-Excited-6717077173221459970?refer=embed">
                ♬ I&#39;m So Excited - The Pointer Sisters
              </Link>
            </p>
          </Caption>
        </Photo>
        <Photo>
          <Image src={Talent} alt="" />
          <Caption>
            From the first performer to the our grand finale, The Pacemakers,
            the joyous spirit and energy was very high!
          </Caption>
        </Photo>
      </Photos>
    </>
  );
};
